import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { graphql, useStaticQuery } from "gatsby";
import Img from 'gatsby-image';
import { Animated } from "react-animated-css";

const hero_slider = ({props, hero_slider}) => {

  const settings = {
      dots: true,
      infinite: true,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
  }
/*
  return (
      <div>
        <Slider {...settings}>
          {
             hero_slider && hero_slider.length && hero_slider?.map(image=> {
              return (
                // <div key={image.node.id}>
                  <a href={image.slide_link}>
                    <img src={image.slide_image.source_url} alt={image.slide_alt_text} key={image.slide_image.id} />
                  </a>
                // </div>
              )
            })
          }
        </Slider>
    </div>
  )*/

  return (

    
    <div class="swiper-wrapper">
         {
             hero_slider && hero_slider.length && hero_slider?.map(image=> {
              return (
                <div class="bg-cover swiper-slide">
                <div class="box-table">
                                        <div class="box-cell">
                                  
                                                        <div class="content">
                                                        <picture>
    {image.slide_link ? (
        <a href={image.slide_link}>
            <source media="(max-width: 767px)" srcSet={image.slide_image.source_url.replace("desktop", "mobile")} />
            <img loading="lazy" src={image.slide_image.source_url} alt={image.slide_alt_text} className="w-100" />
        </a>
    ) : (
        <>
            <source media="(max-width: 767px)" srcSet={image.slide_image.source_url.replace("desktop", "mobile")} />
            <img loading="lazy" src={image.slide_image.source_url} alt={image.slide_alt_text} className="w-100" />
        </>
    )}
</picture>

                                                        </div>
                
                                        </div>
                                    </div>
                                    </div> 
              )
            })
          }

        




     
</div>
      
  );
}




export default hero_slider